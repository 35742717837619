<template>
    <div class="wallet" >
        <Head :title="$t('common.wallet')"></Head>
        <div class="main-box" v-if="walletmy">
            <div class="rate-box">
                <!-- <van-button class="rate-btn">{{$t('common.now_rate')}}</van-button> 
                <span class="dots"></span> -->
                <van-notice-bar 
                    class="rate-notice"
                    scrollable
                    color="#269C17"
                    background="##F7F8FA"   
                >
                    <template #default>
                        <span class="rate-txt">1{{$t('common.$')}} = {{taiprice | number_format}} {{$t('common.฿')}}</span>
                        <span class="rate-txt" v-for="(l,i) in newCurrency" :key='i'> 1{{$t('common.$')}} = {{1/l.rate | number_format}}{{l['name_'+lang]}}</span>
                    </template>
                </van-notice-bar>

                
            </div>
            <div class="wallet-main">
                <div class="wallet-box box-one">
                    <div class="box-left">
                        <p>Bid Token {{$t('common.yue')}}</p>
                        <div class="box-count box-item">
                            <img src="../../assets/wallet/wallet-logo.png" alt="" class="box-logo">
                            <span>{{walletmy[2].usable_balance | int_format}}</span>
                        </div>
                    </div>
                    <div class="box-right">
                        <van-button class="wallet-btn color-sky" @click="gotowhere('wallet-replace')">{{$t('common.buy')}}</van-button>
                        <van-button @click="gotowhere('wallet-bt_history')" class="detail-btn color-white">{{$t('common.jiaoyi_list')}}</van-button>
                        <van-button @click="showTransfer('1032')" class="wallet-btn color-main wallet-btn2">{{$t('add.Transfer')}}</van-button>
                    </div>
                </div>
                <div class="wallet-box box-two">
                    <div class="box-left">
                        <p>{{$t('common.add_yue')}}</p>
                        <div class="box-count-two box-item">
                            <i class="tainum">$</i>
                            <span class="tainum">{{walletmy[0].usable_balance*walletmy[0].rate | number_format}}</span>                         
                        </div>
                        <p class="box-count-three">
                            <span class="yue">≈</span>
                            <!-- walletmy[0].usable_balance*walletmy[0].rate*1000 -->
                            <span>฿ {{walletmy[0].usable_balance   | number_format}}</span>
                        </p>
                    </div>
                    <div class="box-right wallet-two">
                        <div>
                            <van-button @click="showInputBox(true)"  class="wallet-btn color-orange margin15">{{$t('common.withwral')}}</van-button>
                            <van-button @click="showInputBox(false)" class="wallet-btn color-orange">{{$t('common.payout')}}</van-button>
                        </div>
                        <div class="box-right-two">
                            <van-button @click="gotowhere('wallet-thaiDetail')" class="detail-btn color-white">{{$t('common.jiaoyi_list')}}</van-button>
                        </div>
                        <div class="box-right-two">
                            <van-button @click="showTransfer('8011')" class="wallet-btn color-main wallet-btn2">{{$t('add.Transfer')}}</van-button>
                        </div>
                    </div>
                </div>
                <div class="wallet-box box-three">
                    <div class="box-left">
                        <p>PV {{$t('common.yue')}}</p>
                        <div class="box-count-pv box-item">
                            <img class="img-pv" src="../../assets/imgs/zhuan.png" alt="">
                            <span>{{walletmy[1].usable_balance |number_format }}</span>
                        </div>
                    </div>
                    <div class="box-right wallet-two">
                        <div class="box-right-two">
                            <van-button class="wallet-btn color-orange " @click="gotowhere('home-market')">{{$t('common.dui_out')}}</van-button>
                        </div>
                        <div class="box-right-two">
                            <van-button @click="gotowhere('wallet-pvdetail')" class="detail-btn color-white">{{$t('common.jiaoyi_list')}}</van-button>
                        </div>
                        <div class="box-right-two">
                            <van-button @click="showTransfer('1033')" class="wallet-btn color-main wallet-btn2">{{$t('add.Transfer')}}</van-button>
                        </div>
                    </div>
                </div>

            </div>
        </div>

        <!-- 充值成功   提现成功  -->
     <van-popup class="login-popup pay-one" v-model="show" position="bottom"   duration="0.2" >
        <div class="login-popup-box">
            <div class="pop-top">
                <p>{{toptxt}}</p>
                <van-icon name="cross" class="pop-close" @click="show=false" />
            </div>
            <div class="pay-two" >
                <img :src="require('@/assets/images/pay-success.png')" alt="">
                <p>{{$t('wallet.sub_success')}}</p>
                <p class="chong-txt">{{$t('wallet.pay_success_txt')}}</p>
            </div>
            <div class="pay-two" v-if="false">
                <img :src="require('@/assets/images/pay-fail.png')" alt="">
                <p>{{$t('wallet.pay_fail')}}</p>
            </div>
            <van-button class="kown-btn" @click="show=false">{{$t('wallet.kown')}}</van-button>
        </div>
    </van-popup>

         <!-- 充值  -->
            <van-popup class="login-popup pay-one chong-pop " :style="{height:'16.02rem'}" v-model="chongShow" position="bottom"   duration="0.2" >
                <div class="login-popup-box">
                    <div class="pop-top">
                        <p>{{$t('wallet.Recharge')}} </p>
                        <van-icon name="cross" class="pop-close" @click="chongShow=false" />
                    </div>
                    <van-form label-width="180" class="pay-form" ref="payform"  @submit="gotoPayout">
                        <van-field
                            @click="showCardAndGet"
                            :label="$t('wallet.bank')"
                            :placeholder="$t('wallet.empty_bank')"
                            name="bankname"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                            readonly
                            clickable
                            v-model="recharge.bankname"
                            right-icon="arrow-down"
                        >
                        </van-field>
                        <van-field
                            v-model="recharge.username"
                            :label="$t('wallet.name')"
                            :placeholder="$t('wallet.empty_name')"
                            name="username"
                            readonly
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                        >
                        </van-field>
                        <van-field
                            v-model="recharge.bankaccount"
                            type="text"
                            readonly
                            name="bankaccount"
                            :label="$t('info.bank_account')"
                            :placeholder="$t('info.enter_bank_account')"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                        />
                        
                          <van-field
                            :label="$t('wallet.banknum')"
                            :placeholder="$t('wallet.empty_bank')"
                            name="banknum"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                            readonly
                            v-model="recharge.banknum"
                        >
                        </van-field>
                        <van-field
                            v-model="recharge.recharge_amount"
                            :label="$t('wallet.recharge_amount')"
                            :placeholder="$t('wallet.empty_recharge_amount')"
                            name="recharge_amount"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                        ></van-field>
                        <div class="add-pic">
                            <p>{{$t('add.ping')}} <span v-if="recharge.img">({{$t('add.open_big')}})</span>   </p>
                            <input  type="file" name="file" id="download" ref="download" style="display:none" @change="uploadFile()" />
                            <div class="photo-item" @click.stop="clicktodo" v-if="!recharge.img"> 
                                <!-- <img :src="require('@/assets/images/add-pic.png')" v-if="imgShow" alt=""> -->
                                <van-icon name="plus"  />
                                <p :class="lang=='cn'?'':'small-text'">{{$t('add.up_ping')}}</p>
                            </div> 
                            <div class="photo-item" v-else> 
                                <img class="newimg" @click="showBigImg" :src="$host+recharge.img" alt="">
                            </div> 
                        </div>
                       
                    </van-form>
                    <div class="last-btns">
                        <van-button @click="payclick" class="btn-two btn-chong" >{{$t('common.confirm')}}</van-button>
                    </div>
                </div>
            </van-popup>

            <!-- 提现   -->
            <van-popup class="login-popup pay-one  payout-pop" :style="{height:'15.02rem'}" v-model="payoutShow" position="bottom"   duration="0.2" >
                <div class="login-popup-box">
                    <div class="pop-top">
                        <p>{{$t('wallet.withdraw')}}</p>
                        <van-icon name="cross" class="pop-close" @click="payoutShow=false" />
                    </div>
                    <van-form label-width="180" class="pay-form" @submit="withdrawAction" ref="withdrawform">
                        <van-field
                            @click="showCardAndGet"
                            :label="$t('wallet.bank')"
                            :placeholder="$t('wallet.empty_bank')"
                            name="bankname"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                            readonly
                            clickable
                            v-model="card.bankname"
                            right-icon="arrow-down"
                        >

                        </van-field>
                        <van-field
                        v-model="card.username"
                        :label="$t('wallet.name')"
                        :placeholder="$t('wallet.empty_name')"
                        name="username"
                        readonly
                        :rules="[{ required: true, message:$t('login.ptian')}]"
                        >

                        </van-field>
                        <van-field
                            v-model="recharge.bankaccount"
                            type="text"
                            readonly
                            name="bankaccount"
                            :label="$t('info.bank_account')"
                            :placeholder="$t('info.enter_bank_account')"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                            
                        />
                          <van-field
                            :label="$t('wallet.banknum')"
                            :placeholder="$t('wallet.empty_bank')"
                            name="banknum"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                            readonly
                            v-model="card.banknum"
                        >
                        </van-field>
                          <van-field
                        v-model="card.withdraw_amount"
                        :label="$t('wallet.withdraw_amount')"
                        :placeholder="$t('wallet.require_withdraw_amount')"
                        name="withdraw_amount"
                        :rules="[{ required: true, message:$t('login.ptian')}]"
                        >
                        </van-field>
                        <van-field
                        v-model="card.withdrawpin"
                        :label="$t('login.second_pass')"
                        :placeholder="$t('login.required_sec_pass')"
                        :type="pt3"
                        name="withdrawpin"
                        :right-icon="pt3=='password'?'eye':'closed-eye'"
                        @click-right-icon="changePassType3"
                        :rules="[{ required: true, message:$t('login.ptian')}]"
                        >
                        </van-field>
                    </van-form>
                    <div class="last-btns">
                        <van-button class="btn-two btn-chong" @click="cardclick" >{{$t('common.confirm')}}</van-button>
                    </div>
                </div>
            </van-popup>

             <!-- 转账  -->
            <van-popup class="login-popup pay-one   transform-pop" :style="{height:'15.02rem'}" v-model="transferShow" position="bottom"   duration="0.2" >
                <div class="login-popup-box">
                    <div class="pop-top">
                        <p>{{transfer_min.text}} {{$t('add.Transfer')}}</p>
                        <van-icon name="cross" class="pop-close" @click="transferShow=false" />
                    </div>
                    <van-form label-width="240" class="pay-form" @submit="transferAction" ref="transferform">
                       
                        <van-field
                            v-model="transfer.to_uid"
                            :label="'ID'"
                            :placeholder="$t('add.empty_id')"
                            name="id"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                        >

                        </van-field>
                        <van-field
                            v-model="transfer.amount"
                            type="number"
                            name="amount"
                            :label="$t('add.transfer_amount')"
                            :placeholder="$t('add.amount_min')+transfer_min.min"
                            :rules="[
                                { required: true, message:$t('login.ptian')},
                                {validator:validatorAmountMin,message:$t('add.amount_min')+transfer_min.min,trigger:'onBlur'},
                                {
                                    validator:validatorAmount,message:transfer_min.current_id=='1032'?'金额必须为整数':'金额最多保留2位小数',trigger:'onBlur'
                                }
                            ]"
                            
                        />
                          <van-field
                             class="acutal-field"
                            :label="$t('add.last_transfer_amount')+'('+$t('add.transfer_ratio')+ transfer_min.ratio*100+'%)'"
                            :placeholder="$t('add.empty_last_transfer_amount')"
                            name="last_amount"
                            v-model="transfer.last_amount"
                            readonly
                            clickable
                        >
                        </van-field>
                        <van-field
                            v-model="transfer.transferpin"
                            :label="$t('login.second_pass')"
                            :placeholder="$t('login.required_sec_pass')"
                            :type="pt3"
                            name="transferpin"
                            :right-icon="pt3=='password'?'eye':'closed-eye'"
                            @click-right-icon="changePassType3"
                            :rules="[{ required: true, message:$t('login.ptian')}]"
                        >
                        </van-field>
                    </van-form>
                    <div class="last-btns">
                        <van-button class="btn-two btn-chong" @click="transferclick" >{{$t('common.confirm')}}</van-button>
                    </div>
                </div>
            </van-popup>

            <!-- 选择银行卡  -->
            <van-popup  class="login-popup pay-one card-choose" :style="{height:'auto'}" v-model="cardShow" position="bottom"   duration="0.2" >
                <div class="login-popup-box">
                    <div class="pop-top">
                        <p>{{$t('wallet.choose_bank')}}</p>
                        <van-icon name="cross" class="pop-close" @click="cardShow=false" />
                    </div>
                    
                    <van-cell @click="choosecard(l)" clickable class="mycard" v-for="(l,i) in cardList" :key="i">
                        <div class="card-item">
                            <div class="card-top">
                                <img :src="l.bankCategory.image" alt="">
                                <p>{{l.bankCategory.name}}</p>
                            </div>
                            <div  class="card-bottom">
                                <p class="card-tit">{{$t('wallet.card_num')}}</p>
                                <p>{{l.banknum}}</p>
                            </div>
                        </div>
                    </van-cell>
                    <div class="no-card" >
                        <template v-if="user"><p v-if="user.isbank=='0'">{{$t('wallet.no_bank')}}</p></template>
                        <van-button @click="gotowhere('mine-card-add')" class="confirm-btn">{{$t('info.add_card')}}</van-button>
                    </div>
                </div>
            </van-popup>
    </div>
</template>
<script>
import {mapState,mapMutations,mapGetters} from "vuex"
import {randomString} from "@/common"
import {Toast,ImagePreview ,Notify} from "vant"
import {myinfo} from "@/common"
import router from "@/router"
export default {
    // mixins:[myinfo],
    name:'main-walletIndex',
    data(){
        return {
            lang:localStorage.getItem("langData") || 'th',
            show:false,
            chongShow:false,
            payoutShow:false,
            transferShow:false,
            cardShow:false,
            toptxt:this.$t('wallet.withdraw'),
            secpass:"",
            pt3:"password",
            card:{
                bankname:'',
                banknum:''
            },
            recharge:{},
            withdraw:{},
            transfer:{
                amount:''
            },
            transfer_min:{},
            newCurrency:[]
            // user:JSON.parse(localStorage.getItem("dashboard"))
        }
    },
    watch:{
        chongShow(v){
            
        },
        transfer:{
            deep:true,
            handler(v){
                if(this.transfer_min.current_id=="1032"){
                    v.last_amount = parseFloat(v.amount * (1-this.transfer_min.ratio))
                }else{
                    v.last_amount = parseFloat(v.amount * (1-this.transfer_min.ratio)).toFixed(2)
                }
            }
        }
    },
    methods:{
        transferAction(){
            this.$ajax.thaitransfer({
                current_id:this.transfer_min.current_id,
                pin:this.transfer.transferpin,
                to_uid:this.transfer.to_uid,
                transfer_amount:this.transfer.last_amount * 1,
                form_token:randomString()
            }).then(res=>{
                if(res.code==200){
                    this.transferShow = false;
                    this.show = true;
                    this.toptxt = this.$t('add.Transfer')
                    this.transfer = {}
                }
            })
        },
        transferclick(){
            this.$refs.transferform.submit()
        }, 
        validatorAmount(val){
            if(this.transfer_min.current_id=='1032'){
                return val%1===0
            }else{
                var reg = /^([0-9]+|[0-9]+\.{0,1}[0-9]{1,2})$/;
                return reg.test(val)
            }
        },
        validatorAmountMin(val){
            return val>=this.transfer_min.min
        },
        showTransfer(current_id){
            this.transferShow = true;
            this.transfer = {
                amount:''
            }
            var dashboard = JSON.parse(localStorage.getItem('dashboard'))
            if(current_id=="8011"){
                this.transfer_min = {
                    min:dashboard.transferRatio.thai_transfer_min,
                    ratio:dashboard.transferRatio.thai_transfer_ratio,
                    current_id,
                    text:this.$t('wallet.th')
                }
            }else if(current_id =="1032"){
                this.transfer_min = {
                    min:dashboard.transferRatio.bidtoken_transfer_min,
                    ratio:dashboard.transferRatio.bidtoken_transfer_ratio,
                    current_id,
                    text:"Bid Token"
                }
            }else{
                this.transfer_min = {
                    min:dashboard.transferRatio.pv_transfer_min,
                    ratio:dashboard.transferRatio.pv_transfer_ratio,
                    current_id,
                    text:"PV"
                }
            }
        },
        showBigImg(){
            ImagePreview([this.$host+this.recharge.img]);
        },
        clicktodo(){
            this.$refs.download.click();
        },
        uploadFile(){
            // 修改头像 
            this.file = this.$refs.download.files[0];
            let formData = new FormData();
            formData.append('head',this.file);
            this.$ajax.uploaduserhead(formData)
            .then(res=>{
                if(res.code==200){
                    this.recharge = {
                        ...this.recharge,
                        img:res.data.file_path
                    }
                }
            })
        },
        showInputBox(flag){
            if(!this.user.isbank){
                // 没有绑定 
                this.cardShow = true;
            }else{
                if(flag){
                    this.chongShow= true;
                }else {
                    this.payoutShow = true;
                }
                this.$ajax.banklist()
                .then(res=>{
                    this.setCardList(res.data)
                    this.choosecard(res.data[0])
                })
                // console.log(this.cardList)
                // if(!this.cardList.length>0){
                //     Toast.clear();
                   
                // }else{
                //     this.choosecard(this.cardList[0])
                // }
            }
        },
        showCardAndGet(){
            this.cardShow=true ;
        },
        withdrawAction(value){
            this.$ajax.submitWithdraw({
                ...value,
                bankid:this.card.bankid,
                pin:value.withdrawpin,
                form_token:randomString()
            })
            .then(res=>{
                if(res.code==200){
                    this.payoutShow = false;
                    this.show = true;
                    this.toptxt = this.$t('wallet.withdraw')
                    this.card = {...this.card,withdraw_amount:"",withdrawpin:""}
                }
            })
        },
        cardclick(){
            this.$refs.withdrawform.submit()
        }, 
        gotoPayout(value){
            if(this.recharge.img){
                this.$ajax.submitRecharge(
                    {
                        // ...value,
                        recharge_amount:this.recharge.recharge_amount,
                        account:this.user.uid,
                        nickname:this.user.username,
                        img:this.recharge.img,
                        userbankid:this.recharge.bankid,
                        form_token:randomString(),
                    }
                ).then(res=>{
                    if(res.code==200){
                        this.chongShow = false;
                        this.show = true;
                        this.toptxt = this.$t('wallet.Recharge')
                        this.recharge = {...this.recharge,recharge_amount:"",img:""}
                    }
                })
            }else{
                Notify({
                    message: this.$t('add.up_ping'),
                    color: '#ad0000',
                    background: '#ffe1e1',
                });
            }
            
        },
        payclick(){
            this.$refs.payform.submit()
        },
        choosecard(item){
            this.card = {...this.card,bankname:item.bankname,banknum:item.banknum,bankid:item.id,username:item.bankusername}
            this.recharge = {...this.recharge,bankaccount:item.bankaccount,bankname:item.bankname,banknum:item.banknum,bankid:item.id,username:item.bankusername}
            this.cardShow  = false;
        },
        changePassType3(){
          this.pt3 = this.pt3 == "password"?"text":"password"
        },
        gotowhere(name){
            this.$router.push({
                name
            })
        },
        ...mapMutations(['setdashboard','setwalletmy','changetaiprice','setCardList']),
        getUserInfoCheck(flag){
            if(!this.user.username){  // 判断是否有值 
                this.$ajax.dashboard()
                .then(res=>{
                    this.setdashboard(res.data);
                    localStorage.setItem('dashboard',JSON.stringify(res.data))
                    
                })
            }else{
                
            }
        }
    },
    computed:{
        ...mapState(['walletmy','taiprice','cardList','dashboard']),
        // ...mapGetters(['user']),
        user(){
            return JSON.parse(localStorage.getItem("dashboard"))
        }
    },
    mounted(){
        
        this.$ajax.walletmy()
        .then(res=>{
            if(res.code==200){
                var bid = {}
                var th = {}
                var pv = {}
                res.data.list.forEach(item=>{
                    if(item.current_id==1032){
                        bid = {...item}
                    }
                    if(item.current_id==1033){
                        pv = {...item}
                    }
                    if(item.current_id==8011){
                        th = {...item}
                        // this.changetaiprice(item.rate*1000);
                        this.changetaiprice(1/item.rate);
                    }
                })
                this.setwalletmy([th,pv,bid])
                this.newCurrency = res.data.newCurrency;
            }
        })
        
    }
}
</script>